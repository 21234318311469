<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
    <vx-card class="mb-5">
      <div class="vx-row align-items-end">
          <!-- Order Date -->
        <div class="order-filter-item">
          <div class="selectedDate-dropdown">
            <label class="text-sm">Date to search</label>
            <v-select :value="selectedDate" :clearable="false" @input="setSelectedDate" :options="selectDateList"/>
          </div>
        </div>
        <div class="order-filter-item">
          <div class="datepicker-item">
            <label class="text-sm">Date Range</label>
            <flat-pickr v-model="date" :config="{ dateFormat: 'Y-m-d',mode: 'range',showMonths:2 }" class="w-full" name="orderdate"/>
          </div>
        </div>
        <div class="order-filter-item">
          <!-- Agent -->
          <div class="agent-dropdown">
            <label class="text-sm">Agent/Staff Member</label>
            <v-select v-model="agentId" :reduce="x=>x.code" :options="agentList"/>
          </div>
        </div>
        <div class="order-filter-item">
          <div class="order-status">
            <div class="agent-dropdown">
              <label class="text-sm">Order Status (clear to show all)</label>
              <v-select v-model="orderStatusId" :reduce="x=>x.code" :options="statusList"/>
            </div>
          </div>
        </div>
        <div class="order-filter-item">
          <div class="text-field">
            <vs-input class="w-full" label="Name/Order Ref/Booking Ref/Voucher Code/Marketing Source" v-model="searchtext" v-validate="'max:50'" name="Name/Order Ref/Booking Ref"/>
          </div>
        </div>
        <div class="order-filter-item">
            <vs-button  @click="searchOrders" class="">Search</vs-button>
        </div>
    </div>
    </vx-card>
      <vx-card>      
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="ordersList"
          :gridOptions="gridOptions"
          :paginationPageSize="paginationPageSize" 
          :pagination="true"  
          :enableCellTextSelection=true 
          id="order-grid"
           @first-data-rendered="onFirstDataRendered"
           @grid-size-changed="onGridSizeChanged"     
        >
        </ag-grid-vue>
      </vx-card>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
// import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import Vue from "vue"
import TravioAccessControl from '@/components/travio-pro/TravioAccessControl.vue';
import CellRendererIntegrationsActions from './cell-renderers/CellRendererIntegrationsActions.vue'
//import CellRendererCheckbox from './cell-renderers/CellRendererCheckbox.vue'
import { format } from 'date-fns'
export default {
  
  props: {
    applicationId: { required: true }
  },
  components: {
    AgGridVue,
    CellRendererIntegrationsActions,
    TravioAccessControl,
   // CellRendererCheckbox,
    flatPickr
  },
  data () {
    return {
      ordersList: [],
      orderIdPrefix: '',
      agentList: [],
      selectDateList: ['Booking Date', 'Travel Date'],
      statusList: [
        { code: 100, label: 'Confirmed (incl. failed)'}, 
        { code: 3, label: 'Confirmed' }, 
        { code: 4, label: 'Confirmed with failure' },
        { code: 1, label: 'Basket' }, 
        { code: 2, label: 'Processing'},
        { code: 5, label: 'Cancelled'},
        { code: 6, label: 'Cancelled Partial'},
      ],
      gridOptions: null,
      gridApi: null,
      date:null,
      agentId:null,
      selectedDate:null,
      orderStatusId: null,
      searchtext:null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererIntegrationsActions
      },
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 25
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }   
  },
  created () {
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    // Set default values
    this.selectedDate = "Booking Date"
    let today = new Date()
    const todayString = this.formatAsDateOnly(today)
    const sevenDaysAgo = today.setDate(today.getDate() - 7)
    const sevenDaysAgoString = this.formatAsDateOnly(today)
    this.date = `${sevenDaysAgoString} to ${todayString}`
    this.orderStatusId = 100
    
    this.$vs.loading()
    this.$http.get(`api/OrderManage/orderAgents/${this.$store.state.ActiveCompanyId}`)
    .then(response => {
      this.agentList = response.data 
        && response.data.map(x => ({ code: x.userId, label: x.fullName}))
      this.searchOrders();
        
    })
    .catch(error => console.error(error))
    // .finally(() => this.$vs.loading.close())  

    // this.$vs.loading()
    // this.$http.get(`api/OrderManage/apps/${this.applicationId}/myorders`)
    // .then(response => {
    //   var self=this;
    //     this.ordersList = response.data; 
    //     this.selectDateList.push("Booking Date");
    //     this.selectDateList.push("Travel Date");
    //     this.agentList.push({Id:'', value:'Unassigned'});
    //     for(let i = 0; i < response.data.length; i++){
    //       if(response.data[i].agentId && !this.agentList.includes(response.data[i].agentName)){
    //         var objlist={}
    //         objlist['Id']=response.data[i].agentId;
    //         objlist['value']=response.data[i].agentName;
    //       this.agentList.push(objlist);
    //       }
    //     }
    //     for(let j = 0; j < response.data.length; j++){
    //       if(self.statusList.findIndex(x=>x.value==response.data[j].orderStatus)==-1){
    //         var objlist={}
    //         objlist['Id']=response.data[j].orderStatusId;
    //         objlist['value']=response.data[j].orderStatus;
    //       self.statusList.push(objlist)
    //       }
    //     }
        
    // })
    // .catch(error => console.error(error))
    // .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
   
      { 
        headerName: 'Order ID',
        width:70,
        field: 'orderId',
        filter: true,
        wrapText:true,
        valueGetter: params => (this.orderIdPrefix || '') + params.data.orderId
      },
      { headerName: 'Status', width:100,field: 'orderStatus', filter: true,wrapText:true },
      { headerName: 'Agent',width:120,field: 'agentName', filter: true,wrapText:true },
      { headerName: 'Customer', width:120,field: 'customerName',filter: true,wrapText:true },
      { headerName: 'Summary',width:120,field: 'summary',filter: true,wrapText:true },
      { headerName: 'Total', width:80,field: 'tblTotalPrice', sortable: true,wrapText:true},
      { 
        headerName: 'Created Date',
        field: 'createdDate',
        sortable: true,
        width:140,
      },
       {
        headerName: "Actions",
        sortable: false,
        width:70,
        cellRendererFramework: Vue.extend(CellRendererIntegrationsActions)
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 30,
      sortable: true,
      resizable: false,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  
  methods: {
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    formatAsDateOnly(dateObject) {
      var dd = String(dateObject.getDate()).padStart(2, '0');
      var mm = String(dateObject.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = dateObject.getFullYear();

      return `${yyyy}-${mm}-${dd}`
    },
    onGridSizeChanged(params) {
      var gridWidth = document.getElementById('order-grid').offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        var column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    },


    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    setSelectedDate (value) {
      this.selectedDate = value ? value : null
      this.selectedDate = value;
    },
    searchOrders() {
      var self=this;
      this.$vs.loading();  
      var createFromDate=this.date==null?null:this.date.split('to')[0].trim();
        var createToDate=this.date==null?null:this.date.split('to')[1].trim();
        const payload = {
          orderStatusId:this.orderStatusId,
          orderAppId:this.applicationId,
          agentId:this.agentId,
          searchText:this.searchtext==""?null:this.searchtext,
          createdFromDate:createFromDate,
        createdToDate:createToDate,
          dateSelect:this.selectedDate,
      };

      this.$http.post(`api/OrderManage/search`,payload)
        .then(response => {
          self.ordersList = response.data.orders
          self.orderIdPrefix = response.data.orderIdPrefix         

        })
        .catch(error => console.error(error))
        .finally(() => this.$vs.loading.close()) 
  },
  }
}
</script>

<style>
  .order-filter-item {
    padding: 10px 15px 0 15px;
    width: calc(100% / 3);
  }
  .vx-row.align-items-end {
    align-items: flex-end;
  }
  .order-filter-item-btn{
    width:12%;
  }
  .flatpickr-calendar.multiMonth{
        width: 640px !important;
  }  
  .v-select .vs__selected {
    color: #626262;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .vs__selected-options {
      flex-wrap: nowrap;
  }
    .ag-cell, .ag-header-cell-text {
      text-overflow: clip !important;
    overflow: visible !important;
    white-space: normal !important;
    line-height: normal !important;
    word-break: break-word;
}
.ag-cell, .ag-header-cell{
  padding:10px 5px !important;
}
.flatpickr-day.hidden{
   display: block !important;
   visibility: visible !important;
}
.ag-theme-material{
  width:100%;
}
</style>
